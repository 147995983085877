// Typography

// prettier-ignore
$body-font-family: system-ui, -apple-system, blinkmacsystemfont, "Segoe UI",
roboto, "Helvetica Neue", arial, sans-serif, "Segoe UI Emoji";
$mono-font-family: "SFMono-Regular", menlo, consolas, monospace;
$root-font-size: 16px; // DEPRECATED: previously base font-size for rems
$body-line-height: 1.4;
$content-line-height: 1.6;
$body-heading-line-height: 1.25;

// Font size
// `-sm` suffix is the size at the small (and above) media query

$font-size-1: 0.5625rem;
$font-size-1-sm: 0.625rem;
$font-size-2: 0.6875rem; // h4 - uppercased!, h6 not uppercased, text-small
$font-size-3: 0.75rem; // h5
$font-size-4: 0.875rem;
$font-size-5: 1rem; // h3
$font-size-6: 1.125rem; // h2
$font-size-7: 1.5rem;
$font-size-8: 2rem; // h1
$font-size-9: 2.25rem;
$font-size-10: 2.625rem;
$font-size-10-sm: 3rem;

// Colors

$white: #fff;
$grey-dk-000: #959396;
$grey-dk-100: #5c5962;
$grey-dk-200: #44434d;
$grey-dk-250: #302d36;
$grey-dk-300: #27262b;
$grey-lt-000: #f5f6fa;
$grey-lt-100: #eeebee;
$grey-lt-200: #ecebed;
$grey-lt-300: #e6e1e8;
$purple-000: #7253ed;
$purple-100: #5e41d0;
$purple-200: #4e26af;
$purple-300: #381885;
$blue-000: #2c84fa;
$blue-100: #2869e6;
$blue-200: #264caf;
$blue-300: #183385;
$green-000: #41d693;
$green-100: #11b584;
$green-200: #009c7b;
$green-300: #026e57;
$yellow-000: #ffeb82;
$yellow-100: #fadf50;
$yellow-200: #f7d12e;
$yellow-300: #e7af06;
$red-000: #f77e7e;
$red-100: #f96e65;
$red-200: #e94c4c;
$red-300: #dd2e2e;

// Spacing

$spacing-unit: 1rem; // 1rem == 16px

$spacers: (
        sp-0: 0,
        sp-1: $spacing-unit * 0.25,
        sp-2: $spacing-unit * 0.5,
        sp-3: $spacing-unit * 0.75,
        sp-4: $spacing-unit,
        sp-5: $spacing-unit * 1.5,
        sp-6: $spacing-unit * 2,
        sp-7: $spacing-unit * 2.5,
        sp-8: $spacing-unit * 3,
        sp-9: $spacing-unit * 3.5,
        sp-10: $spacing-unit * 4,
);
$sp-1: map-get($spacers, sp-1); // 0.25 rem == 4px
$sp-2: map-get($spacers, sp-2); // 0.5  rem == 8px
$sp-3: map-get($spacers, sp-3); // 0.75 rem == 12px
$sp-4: map-get($spacers, sp-4); // 1    rem == 16px
$sp-5: map-get($spacers, sp-5); // 1.5  rem == 24px
$sp-6: map-get($spacers, sp-6); // 2    rem == 32px
$sp-7: map-get($spacers, sp-7); // 2.5  rem == 40px
$sp-8: map-get($spacers, sp-8); // 3    rem == 48px
$sp-9: map-get($spacers, sp-9); // 3.5  rem == 56px
$sp-10: map-get($spacers, sp-10); // 4  rem == 64px

// Borders

$border: 1px solid;
$border-radius: 4px;
$border-color: $grey-lt-100;

// Grid system

$gutter-spacing: $sp-6;
$gutter-spacing-sm: $sp-4;
$nav-width: 16.5rem;
$nav-width-md: 15.5rem;
$nav-list-item-height: $sp-6;
$nav-list-item-height-sm: $sp-8;
$nav-list-expander-right: true;
$content-width: 63rem;
$header-height: 3.75rem;
$search-results-width: $content-width - $nav-width;
$transition-duration: 400ms;

// Media queries in pixels

$media-queries: (
        xs: 20rem,
        sm: 31.25rem,
        md: $content-width,
        lg: $content-width + $nav-width,
        xl: 87.5rem,
);
