

$logo: "https://cdn.retinalscreenings.com/hosts/iris/images/app-logos/logo.svg";

@import "./support/support";
@import "./custom/setup";
@import "./color_schemes/light";

@import "./color_schemes/light-custom.scss";

@import "./modules";
div.opaque {
  background-color: $body-background-color;
}
@import "./custom/custom";


